import React, { Component } from "react";
import Helmet from "react-helmet";
import {
  FormControl,
  TextField,
  Button
} from "@material-ui/core";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import constants from "../Constants/constants";

class Decks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      decks: [],
      visibleDecks: 24, // Tracks how many decks to show at a time
    };
  }

  componentDidMount() {
    this.fetchDecks();
  }

  fetchDecks(search = "") {
    let endpoint = `${constants.DOMAIN}api/v1/guest/deck?search=${search}`;
    fetch(endpoint, {
      mode: "cors",
      method: "GET",
    })
      .then(async (res) => {
        res = await res.json();
        let decks = res.decks.sort((a, b) =>
          a.updatedAt > b.updatedAt ? -1 : a.updatedAt < b.updatedAt ? 1 : 0
        );
        this.setState({
          decks: decks,
          visibleDecks: 24, // Reset visibleDecks when a new search occurs
        });
      })
      .catch((err) => {
        console.log(err);
        alert("There was an error, try again later");
      });
  }

  loadMoreDecks() {
    this.setState((prevState) => ({
      visibleDecks: prevState.visibleDecks + 24,
    }));
  }

  handleSearchChange(value) {
    this.setState({ search: value }, () => {
      this.fetchDecks(this.state.search);
    });
  }

  renderTextInput(
    id,
    label,
    value,
    width,
    inputProps,
    disabled = false,
    error = false
  ) {
    return (
      <FormControl style={{ width: width }}>
        <TextField
          error={error}
          variant="outlined"
          id={id}
          label={label}
          value={value || ""}
          disabled={disabled}
          InputProps={inputProps}
          autoComplete="nope"
          onChange={(inputValue) => {
            this.handleSearchChange(inputValue.target.value);
          }}
        />
      </FormControl>
    );
  }
  handleDeckClick(deckId) {
    this.props.history.push(`/deck/${deckId}`);
  }
  render() {
    const { search, decks, visibleDecks } = this.state;
    const filteredDecks = decks.filter((d) => d.deck.length > 49);

    return (
      <div>
        <Helmet>
          <title>Community Decks</title>
          <meta
            name="description"
            content="Checkout all of the decks the community has built on the Primal TCG App!"
          />
          <meta
            property="og:title"
            content="Primal TCG Community Decks"
          />
          <meta
            property="og:description"
            content="Checkout all of the decks the community has built on the Primal TCG App!"
          />
          <meta
            property="og:image"
            content="https://pbs.twimg.com/media/FxkDQAsWYAImo4-?format=jpg&name=4096x4096"
          />
        </Helmet>
        <div className="row bkg-red full-h">
          <Navbar />
          <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
            <h1 className="mb-2vh">Filter Deck</h1>
            <div className="container mb-2vh">
              {this.renderTextInput(
                "search",
                "Search by Deck Name",
                search,
                "100%"
              )}
              <div className="row">
                {filteredDecks.slice(0, visibleDecks).map((deck) => (
                  <div
                    key={deck._id}
                    style={{
                      border: "2px solid #efefef",
                      borderRadius: "15px",
                      paddingTop: "15px",
                    }}
                    className="col-sm-2"
                    onClick={() => this.handleDeckClick(deck._id)}
                  >
                    <img
                      className="mappedCard"
                      src={constants.DOMAIN + deck.image}
                      alt={deck.name}
                    />
                    <h3>{deck.name}</h3>
                  </div>
                ))}
              </div>
              {visibleDecks < filteredDecks.length && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.loadMoreDecks()}
                >
                  Load More
                </Button>
              )}
            </div>
          </div>
          <Footer />
        </div>
        
      </div>
    );
  }
}

export default Decks;
