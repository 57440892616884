import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './index.css';
import Landing from './components/Portal/Landing';
import FrontPage from './components/Front';
import Android from './components/Android/Android';
import Legal from './components/Terms/Legal';
import About from "./components/Company/About";
import Rulebook from './components/Rulebook/Rulebook';
import Salesheet from './components/Salesheet/Salesheet';
import Search from './components/Search/Search';
import Results from './components/Search/Results/Results';
import Card from "./components/Card/Card";
import Pack from "./components/Packs/Pack";
import Artist from "./components/Artists/Artists";
import ArtistsList from './components/Artists/ArtistsList';
import SignUp from './components/SignUp/SignUp';
import Events from './components/Events/Events';
import TPSurvey from './components/Events/TPSurvey';
import Preorder from './components/Preorder/Preorder';
import IndividualPreorder from './components/Preorder/IndividualPreorder';
import BusinessPreorder from './components/Preorder/BusinessPreorder';
import Confirmation from './components/Preorder/Confirmation';
import OrderConfirmation from './components/Preorder/OrderConfirmation';
import OPTour from "./components/Updates/OPTour";
import Ambassador from "./components/Updates/Ambassador";
import Prerelease from "./components/Updates/Prerelease";
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import path from 'path';
import fs from 'fs';
import Decks from './components/Decks/Decks';
import DeckViewer from './components/Decks/DeckViewer';

// const pdfjsDistPath = path.dirname(require.resolve('pdfjs-dist/package.json'));
// const pdfWorkerPath = path.join(pdfjsDistPath, 'legacy', 'build', 'pdf.worker.js');

// fs.copyFileSync(pdfWorkerPath, './dist/pdf.worker.js');

unregister();
ReactDOM.render(
  <Router>
    <div>
      <Route exact path='/create-account' component={SignUp} />
      <Route exact path={`/artist/:name`} component={Artist} />
      <Route exact path={'/artists'} component={ArtistsList} />
      <Route exact path={`/card/:id`} component={Card} />
      <Route exact path={`/pack/:id`} component={Pack} />
      <Route exact path={`/search/results/:query`} component={Results} />
      <Route exact path={`/search`} component={Search} />
      <Route exact path='/salesheet' component={Salesheet} />
      <Route exact path='/order-confirmation' component={Confirmation} />
      <Route exact path='/rulebook' component={Rulebook} />
      <Route exact path='/dattebayo' component={About} />
      <Route exact path='/portal' component={Landing} />
      <Route exact path='/preorder' component={Preorder} />
      <Route exact path='/tpsurvey' component={TPSurvey} />
      <Route exact path='/optour' component={OPTour} />
      <Route exact path='/ambassador' component={Ambassador} />
      <Route exact path='/prerelease' component={Prerelease} />
      <Route exact path='/preorder-confirmation' component={Confirmation} />
      <Route exact path='/bethesda' component={IndividualPreorder} />
      <Route exact path='/preorder-b2b' component={BusinessPreorder} />
      <Route exact path='/events' component={Events} />
      <Route exact path='/terms' component={Legal} />
      <Route exact path='/decks' component={Decks} />
      <Route path="/deck/:id" component={DeckViewer} />
      <Route exact path='/android' component={Android} />
      <Route exact path='/' component={FrontPage} />
    </div>
  </Router>,
  document.getElementById('root')
);