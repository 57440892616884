import React, { Component } from 'react';
import Helmet from 'react-helmet';
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import constants from "../Constants/constants";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      card: null
    }
  }
  componentDidMount() {
    const urlSplit = this.props.match.url.split("/");
    let cardID = urlSplit[urlSplit.length - 1];
    let cachedData = localStorage.getItem("card");
    if (cachedData) {
      let card = JSON.parse(cachedData);
      if (card._id === cardID) {
        this.setState({
          card: card
        });
      } else {
        this.getCardData(cardID);  
      }
    } else {
      this.getCardData(cardID);
    }
    // if (lastChar.includes("custom=")) {
    //   this.fetchPredefinedCards(lastChar.substring(7));
    // }
  }
  async getCardData(cardID) {
    try {
      let result = await fetch(`https://api.primaltcg.com/api/v1/guest/post/${cardID}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });  
        result = await result.json();
      this.setState({
        card: result
      });
    } catch (error) {
      alert("There was an error getting this card");
      this.props.history("/search");
    }
  }
  renderCardData() {
    const { card } = this.state;
    let Hcombat,Hsupport,Icombat,Isupport;
    if (card && card.cardType === "Character") {
      Hcombat = card.healthy.substring(0,1);
    Hsupport = card.healthy.substring(2,3);
    Icombat = card.injured.substring(0,1);
    Isupport = card.injured.substring(2,3);
    console.log("HEALTH COMBAT", Hcombat, Hsupport, Icombat, Isupport)
    }
    return (<div style={{ padding: "15px"}} className="row">
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <img className="mappedCard" src={constants.DOMAIN + card.image} />
        {/* <h3 style={{ fontWeight: "bold"}} >Lore</h3>
        <h5 style={{ fontStyle: "italic", fontWeight: "500"}}>"{card.metadata}"</h5> */}
      </div>
      <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <h1>{card.name}</h1>
        <div className="row">
          <div className="col-sm-6">
            <span style={{ display: "flex", alignItems: "baseline"}}>
              <h3 style={{ fontWeight: "bold"}}>Card Type:</h3> <h5 style={{ marginLeft: "5px"}}>{card.cardType}</h5>
            </span>
          </div>     
          {card.cardType === "Character" ? (<div className="col-sm-6">
            <span style={{ display: "flex", alignItems: "baseline"}}>
              <h3 style={{ fontWeight: "bold"}}>Attribute:</h3> <h5 style={{ marginLeft: "5px"}}>{card.skill.replace(" ", "/")}</h5>
            </span>
          </div> ) : (<div />)}
                  
        </div>
        {card.cardType === "Character" || card.cardType === "Strategy" ? (        <div className="row">
          <div className="col-sm-6">
          <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Turn Cost:</h3> <h5 style={{ marginLeft: "5px"}}>{card.turnCount}</h5>
        </span>
          </div>          
          <div className="col-sm-6">
          <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Hand Cost:</h3> <h5 style={{ marginLeft: "5px"}}>{card.handCost}</h5>
        </span>
          </div>         
        </div>) : (<div />) }

        <div className="row">
          <div className="col-sm-6">
            <h3 style={{ fontWeight: "bold"}} >Element(s)</h3>
            <ul>
              {card.element.map((e, ind) => (
                <li key={e + ind}><h5>{e}</h5></li>
              ))}
            </ul>
          </div>          
          {card.cardType === "Character" ? (<div className="col-sm-6">
            <h3 style={{ fontWeight: "bold"}} >Characteristics</h3>
            <ul>
              {card.characteristic.map((char, ind) => (
                <li key={char + ind}><h5>{char}</h5></li>
              ))}
            </ul>
          </div>) : (<div />)}         
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h3 style={{ fontWeight: "bold"}} >Effect</h3>
            <h5 style={{fontWeight: "500"}}>{card.text}</h5>
          </div>                  
        </div>
        {card.cardType === "Character" ? (<div className="row">
          <div className="col-sm-6">
            <h3 style={{ fontWeight: "bold"}} >Healthy Stats</h3>
            <span style={{ display: "inline-flex"}}>
              <h5>Combat: {Hcombat}</h5> <h5 style={{ marginLeft: "15px"}}>Support: {Hsupport}</h5>
            </span>
          </div>          
          <div className="col-sm-6">
            <h3 style={{ fontWeight: "bold"}} >Injured Stats</h3>
            <span style={{ display: "inline-flex"}}>
              <h5>Combat: {Icombat}</h5> <h5 style={{ marginLeft: "15px"}}>Support: {Isupport}</h5>
            </span>
          </div>          
        </div>) : (<div />)}
        <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Rarity:</h3> <h5 style={{ marginLeft: "5px"}}>{card.rarity}</h5>
        </span>
        <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Card Number:</h3> <h5 style={{ marginLeft: "5px"}}>{card.cardNumber}</h5>
        </span>
        <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Card Set:</h3> <h5 style={{ marginLeft: "5px"}}>{card.cardSet}</h5>
        </span>
        <span style={{ display: "flex", alignItems: "baseline"}}>
          <h3 style={{ fontWeight: "bold"}}>Artist:</h3> <h5 onClick={() => { card.artist ? window.open(card.artist.link): console.log("unavailable")}} style={{ marginLeft: "5px", cursor: "pointer", color: "#1aa6dd", fontWeight: "bold"}}>{card.artist ? card.artist.name : ""}</h5>
        </span>
        
      </div>
    </div>);
  }
  render() {
    return (
      <div className="Card">
        <Helmet>
          <title>{this.state.card ? this.state.card.name + " |" : ""} Primal Trading Card Game</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red">
        <div className="col-xs-12 col-sm-8 col-md-8 col-xl-8 offset-sm-2 offset-md-2 offset-xl-2 floating-section">
          {this.state.card ? this.renderCardData() : (<h1>Invalid Card Data</h1>)}
          
        </div>
      </div>
      <Footer />
      </div>
    );
  }
}