import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import constants from '../Constants/constants';

class DeckViewer extends Component {
  state = {
    deck: [],
    name: "",
    loading: true,
    error: null,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    const endpoint = `${constants.DOMAIN}api/v1/guest/deck/${id}`;

    fetch(endpoint, {
      mode: 'cors',
      method: 'GET',
    })
      .then(async (res) => {
        if (!res.ok) throw new Error('Failed to fetch deck');
        const deck = await res.json();
        this.setState({ deck: deck.deck, loading: false, name: deck.name });
      })
      .catch((err) => {
        this.setState({ error: err.message, loading: false });
      });
  }
  sortByTurnCount(cards) {
    return cards.sort((a, b) => a.turnCount - b.turnCount);
  }
  
  // ... (other methods and render function)
  categorizeCards(cards) {
    console.log("CATEGORIZE CARDS", cards);
    const categories = {
      characters: [],
      strategies: [],
      abilities: [],
      fieldCard: [],
    };
  
    cards.forEach((card) => {
      switch (card.cardType) {
        case 'Character':
          categories.characters.push(card);
          break;
        case 'Strategy':
          categories.strategies.push(card);
          break;
        case 'Ability':
          categories.abilities.push(card);
          break;
        case 'Field':
          categories.fieldCard.push(card);
          break;
        default:
          break;
      }
    });
  
    return categories;
  }
  render() {
    const { deck, loading, error, name } = this.state;
  
    if (error) return <p>Error: {error}</p>;
  
    const { characters, strategies, abilities, fieldCard } = this.categorizeCards(deck);
    console.log({ characters, strategies, abilities, fieldCard })
    return (
      <>
      <Helmet>
          <title>{this.state.name ? this.state.name + " Deck |" : ""} Primal Trading Card Game</title>
          <meta name="description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:title" content="Primal Trading Card Game (TCG) | Awakened Primordials 1st Ed Release April 2024" />
          <meta property="og:description" content="Primal the Trading Card Game is a new TCG for highly skilled TCG players. This is an anime & fantasy art TCG with exciting gameplay and compares to no other existing game.. 1st Ed Release April 2024, join our mailing list to get your first free Primal TCG card and download the Primal TCG app to see all of the available cards. Game designed by Dattebayo Labs LLC ." />
          <meta property="og:image" content="https://pbs.twimg.com/media/GJ38D7mWwAAMXsa?format=jpg&name=medium" />
        </Helmet>
        <Navbar />
        <div className="row bkg-red">
        <div className="col-xs-12 col-sm-10 col-md-10 col-xl-10 offset-sm-1 offset-md-1 offset-xl-1 floating-section">
        {loading ?  <CircularProgress /> : (<div className='row'>
        {fieldCard && (
          <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
            <h2>Field Card</h2>
          {fieldCard.map((card) => (
            <img
              key={card.id}
              src={`${constants.DOMAIN}${card.image}`}
              alt={card.name}
              className='mappedCard'
              onClick={() => { this.props.history.push(`/card/${card._id}`)}}
            />))}
        </div>
        )}
        <div className='col-9'>
        <h1>{name}</h1>
        <div>
          <h2>Characters ({`${characters.length}`})</h2>
          {this.sortByTurnCount(characters).map((card) => (
            <img
              key={card.id}
              src={`${constants.DOMAIN}${card.image}`}
              alt={card.name}
              style={{ width: '150px', margin: '10px', borderRadius: "5px", cursor: 'pointer' }}
              onClick={() => { this.props.history.push(`/card/${card._id}`)}}
            />
          ))}
        </div>
  
        <div>
          <h2>Strategies ({`${strategies.length}`})</h2>
          {this.sortByTurnCount(strategies).map((card) => (
            <img
              key={card.id}
              src={`${constants.DOMAIN}${card.image}`}
              alt={card.name}
              style={{ width: '150px', margin: '10px', borderRadius: "5px", cursor: 'pointer' }}
              onClick={() => { this.props.history.push(`/card/${card._id}`)}}
            />
          ))}
        </div>
  
        <div>
          <h2>Abilities ({`${abilities.length}`})</h2>
          {abilities.map((card) => (
            <img
              key={card.id}
              src={`${constants.DOMAIN}${card.image}`}
              alt={card.name}
              style={{ width: '150px', margin: '10px', borderRadius: "5px", cursor: 'pointer' }}
              onClick={() => { this.props.history.push(`/card/${card._id}`)}}
            />
          ))}
        </div>
        </div>
        </div>)}
        
        
        
        </div>
        <Footer />
      </div>
      </>
     
    );
  }
  
}

export default DeckViewer;