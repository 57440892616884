import React, { Component } from 'react';
import primalLogo from '../assets/PrimalIcon.png';

export default class Navbar extends Component {
  render() {
    return (
      <div className="row">
      <div className="col-md-12 col-xs-12">
        <nav className="navbar navbar-expand-lg fixed-top navbar-transparent nav-down">
          {/* <div className="container"> */}
            <a className="navbar-brand" href="/"><img alt="Primal Trading Card Game Logo" src={primalLogo} /></a>
            <button style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)"
              }} className="navbar-toggler navbar-toggler-right" 
              type="button" data-toggle="collapse" data-target="#navbar-menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <p style={{
                fontWeight: "bold",
                color: "rgb(255, 186, 6)"
              }}>Menu</p>
            </button>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item">
                  <a className="nav-link" href="#Admins">Admin Portal</a>
                </li> */}
                {/* <li className="nav-item">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link" href="/#Awakened-Power">Awakened Primordials</a>
                </li>
                <li className="nav-item dropdown">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#Cards" role="button" aria-haspopup="true" aria-expanded="false">Characters</a>
                  <ul className="dropdown-menu dropdown-menu-right dropdown-warning">
                    <div   className="dropdown-header">Archetypes</div>
                    <a className="dropdown-item" href="/search/results/custom=micromons">Micromons</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/search/results/custom=cayne-pirates">Cayne Pirates</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/search/results/custom=pleaguis">Pleaguis</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/search/results/custom=draginked">Draginked</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/search/results/custom=symbiotes">Dragons</a>
                  </ul>
                </li> */}
                {/* <li className="nav-item dropdown">
                  <a style={{ color: "#0dce78", backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)" }} className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#Cards" role="button" aria-haspopup="true" aria-expanded="false">Released Packs</a>
                  <ul className="dropdown-menu dropdown-menu-right dropdown-warning">
                    <div className="dropdown-header">Released Packs</div>
                    <a className="dropdown-item" href="/pack/62ec22806e83c37f9d088044">2022 Primordial Promo Pack</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/pack/644be0aac3eb8a2773749764">Kickstarter Awakened Primordials (AP)</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/pack/6300e3b6c988fe01417f85ca">Kickstarter Collector's Pack</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/pack/644be991c3eb8a2773749765">2023 Promo Pack</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/pack/65a9ca05bc773b7430ada213">2024 Slayer Guild Starter Deck</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/pack/65a99980bc773b7430ada1f9">2024 Sea Invasion Starter Deck</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="/search/results/custom=symbiotes">Dragons</a>
                  </ul>
                </li>  */}
                {/* https://docs.google.com/document/d/1Um3-j4f_O0Q4T6rbvw-ItVrFFDOR2gs9XeT9xYDvSc4/edit?usp=sharing */}
                <li className="nav-item">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link" href="/search">Card Search</a>
                </li>
                <li className="nav-item">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link" href="/decks">Community Decks</a>
                </li>
                <li className="nav-item">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link" href="https://shop.primaltcg.com">Buy Primal TCG</a>
                </li>
                <li className="nav-item dropdown">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Play & Rules</a>
                  <ul className="dropdown-menu dropdown-menu-right dropdown-warning">
                  <div className="dropdown-header">Organized Play</div>
                    <a className="dropdown-item" href="https://play.primaltcg.com">Primal Play Network</a>
                    <div className="dropdown-header">Rulebooks</div>
                    <a className="dropdown-item" href="https://docs.google.com/document/d/1Um3-j4f_O0Q4T6rbvw-ItVrFFDOR2gs9XeT9xYDvSc4/edit?usp=sharing">Comprehensive Rulebook</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item"href="/rulebook">General Rulebook</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item"href="https://docs.google.com/document/d/1_Qktc3NAbwpxCt_Ikwe-037BcpyQ75RyYEFR2qFm-0g/edit?usp=sharing">Tournament Policy</a>
                  </ul>
                </li> 
                <li className="nav-item">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link" href="/artists">Artists</a>
                </li>
                <li className="nav-item dropdown">
                  <a style={{
                backdropFilter: "blur(16px)",
                backgroundColor: "rgba(0, 0, 0, .05)",
                color: "rgb(255, 186, 6)"
              }} className="nav-link dropdown-toggle" data-toggle="dropdown" href="/#Contact" role="button" aria-haspopup="true" aria-expanded="false">Contact</a>
                  <ul className="dropdown-menu dropdown-menu-right dropdown-warning">
                    <div className="dropdown-header">Contact</div>
                    <a className="dropdown-item" href="/#contact-us">Message Us</a>
                    <div className="dropdown-divider"></div>
                    {/* <a className="dropdown-item" href="/#betaTest">Beta Test</a> */}
                  </ul>
                </li>
              </ul>
            </div>
          {/* </div> */}
        </nav>
      </div>
    </div>
    );
  }
}